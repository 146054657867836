<template>
    <div class="date bodyS" v-if="conferenceDate">{{ conferenceDate }}</div>
</template>

<script lang="ts" setup>
const props = defineProps<{ conference: any }>()
const conferenceDate = computed(() => {
    if (props.conference && props.conference.date_start && props.conference.date_end) {
        const fmt = new Intl.DateTimeFormat("en", { year: "numeric", month: "long", day: "numeric" });
        return fmt.formatRange(new Date(props.conference.date_start), new Date(props.conference.date_end));
    } else return null
})
</script>